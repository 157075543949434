/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "在我上大学时，AI与机器学习似乎还是听起来新鲜的、专属于研究人员的概念。但当我毕业后的2022年11月，OpenAI向世界展示了ChatGPT，AI突然成为了被大众可以每天使用的商业化产品。越来越多的人开始谈论AI技术能带给人类的可能性，和他们对AI的个人观点。今天我想要聊的，是我理想中AI会对未来的工作模式，以及劳动力市场的发展趋势的影响。")), "\n", React.createElement("h4", null, React.createElement(_components.p, null, "💡 AI会让人们失业，而这应当是令人兴奋的。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "我当前的工作是软件行业的质量检测员。每一天，我打开公司的软件产品，试图验证新增加的功能是否如期工作，以及代码的变动是否搞坏了已有的老功能。固然，软件的交付质量对软件产品的成功有密不可分的关系。但当我反思自己：这份质量检测员的工作，真的能最大限度地发挥我作为一个人类的创造力吗？")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "我给自己的答案是否定的。如果AI能够通过学习源代码和服务架构，直接为程序员生成可能的功能、性能和安全漏洞报告，并告诉产品经理，应该对哪些受影响的用户流程进行验收测试，那么我这个质量检测员就可以高枕无忧地退休了。到时候，我也将被迫或主动地离开当前的职位，去从事其他更符合时代的工作了。")), "\n", React.createElement("h4", null, React.createElement(_components.p, null, "💡 细分工种的壁垒会被打破，取而代之的是持续学习的超级工作者。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "以我当前所在的软件行业举例，从产品研发到交付的过程中有很多的工种：产品经理、UIUX设计、前端程序员、后端程序员、数据分析师、质量检测员、运维人员等等。在过去的20多年中，敏捷、流水线、看板等类似上世纪丰田公司的工厂管理模式，是行业的主流。这意味着每个人只做一份工作，例如身为质量检测员的我，并不参与软件代码的书写和调试，我也曾认为这是软件行业管理和工作模式的最优解。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "直到AI时代到来，我开始对“超级工作者”的概念感兴趣起来。有了AI的帮助，我可以跳过漫长的编程基础课程，而快速了解代码的框架和功能的实现方式。有了AI的帮助，我不需要了解CI/CD流水线的每个细节，就可以将自动化测试的环节添加进去。逐渐地，软件交付流程中各个细分工作者之间的墙，开始无声的坍塌。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "想象一下，若是一个软件功能从需求确认到最终部署或交付，都由一个人独立完成，那会怎么样？我们不必担心需求的来源在任务交接中丢失，也不必浪费时间等待上一个环节的工程师休假回来。Peer Programming与Test driven development，也不再是虚无缥缈的概念。两个或者多个超级工作者之间可以在提前计划好的checkpoint或是milestone时，互相review彼此的工作，以免单一工作者的认知和执行偏差导致产品的失败。领导者和管理者则应当担任拉拉队长，致力于确认个人超级工作者的成果与企业想要达成的目标和愿景一致，而赋予个人工作者充分的人文关怀、工作时间和工作地点的自由。")), "\n", React.createElement("h4", null, React.createElement(_components.p, null, "💡 客户成功工程师，是每个人的终极目标。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "我时常鼓励我其他行业的朋友学习编程或者软件技术，因为没有哪个行业的全部资料，都像这样能在网络上轻易地获取和学习到。我们的生活已经被电子产品和安装在上面的应用彻底改变，再过几年，编程技能应该像用键盘打字一样被几乎所有人类掌握。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "与其做一个自怨自艾的被开除的打字员或质量检测员，不如开始聆听自己和身边的人的对话吧。放大你的感官，识别生活中微小的机会，你自己可能就是你自己产品的潜在客户。能为客户创造多少价值，决定了你能在人类社会换取到多少钱。钱并稀罕，也不值钱，但能为我们有限的生命换取更好的人生体验，和更多的快乐和自由。")), "\n", React.createElement("p", {
    className: "py-2"
  }, React.createElement(_components.p, null, "祝大家都能在AI的时代里，成为拥有快乐和自由的超级工作者。")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
